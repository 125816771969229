@use '../abstracts/colors';
@use '../base/animations';

.header {
	display: grid;
	grid-template-columns: 30% 60%;
	justify-content: center;
	align-items: center;
	padding: 1rem 0;
	z-index: 5;
	background-color: colors.$color-white;
	position: sticky;
top: 0;
bottom: 0;
}

.logo {
	&Text {
		margin-left: 1rem;
		font-size: 1.4rem;
	}
	&Container {
		display: flex;
		flex-grow: 1;
		flex-basis: 0;
		justify-content: flex-start;
		align-items: center;
		z-index: 5;
		position: relative;
	}
}

.cartIconContainer {
	z-index: 5;
	display: flex;
	justify-content: flex-end;

	& ion-icon {
		transition: all 0.3s;
		cursor: pointer;
		font-size: 2.4rem;

		&:hover {
			transform: scale(1.1);
		}
	}
}

.menuWrap {
	display: flex;
	position: relative;
	align-items: center;
	justify-content: flex-end;
	z-index: 1;
	& .toggler {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 2;
		cursor: pointer;
		width: 5rem;
		height: 3rem;
		opacity: 0;
	}
	& .hamburger {
		top: 0;
		right: 0;
		z-index: 1;
		width: 5rem;
		height: 2rem;
		padding: 1rem;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all 0.3s ease-in;
		will-change: transform, scale;
		& > div {
			position: relative;
			width: 100%;
			height: 1.8px;
			background: colors.$color-grey-dark;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: all 0.3s ease;
			&::before,
			&::after {
				content: '';
				position: absolute;
				z-index: 1;
				top: -0.8rem;
				width: 100%;
				height: 1.8px;
				background: colors.$color-grey-dark;
			}
			&::after {
				top: 0.8rem;
			}
		}
	}

	& .toggler:checked + .hamburger > div {
		transform: rotate(135deg);
		&::before,
		&::after {
			top: 0;
			transform: rotate(90deg);
		}
	}
}

// ABOVE 544px (For smaller tablets)
@media only screen and (min-width: 34em) {
}

// ABOVE 704px (For Tablets)
@media only screen and (min-width: 44em) {
}
// ABOVE 944px (For Landscape tablets)
@media only screen and (min-width: 59em) {
}

// ABOVE 1200px (For smaller desktops)
@media only screen and (min-width: 75em) {
	.menuBtn {
		display: none;
	}
	.header {
		display: grid;
		grid-template-columns: 20% 60% 20%;
		justify-content: center;
		align-items: center;
		padding: 1rem 8rem;
		z-index: 6;
	}
	.logo {
		&Text {
			margin-left: 1rem;
			font-size: 1.4rem;
		}
		&Container {
			display: flex;
			flex-grow: 1;
			flex-basis: 0;
			justify-content: flex-start;
			align-items: center;
		}
		&ImgContainer {
			position: relative;
			cursor: pointer;
		}
	}

	.cartIconContainer {
		display: flex;
		justify-content: flex-end;

		& ion-icon {
			transition: all 0.3s;
			cursor: pointer;
			font-size: 2.4rem;

			&:hover {
				transform: scale(1.1);
			}
		}
	}
}

// ABOVE 1344px (For desktops)
@media only screen and (min-width: 84em) {
}
