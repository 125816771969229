@use '../abstracts/colors';
@use '../base/animations';

.mainNav {
	color: colors.$color-grey-dark;
	&List {
		display: flex;
		flex-direction: column;
		width: auto;
		justify-content: center;
		align-items: center;
		font-size: 1.8rem;
		gap: 4rem;
		letter-spacing: 0.1rem;
		&Item {
			&:hover {
				color: colors.$color-primary;
			}
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			position: relative;
			flex-direction: column;
			& ion-icon {
				transition: all 0.3s ease-out;
			}
			& > span {
				display: flex;
				justify-content: center;
				align-items: center;
			}

			& > .subMenu {
				position: relative;
				display: none;
				border-radius: 0.25rem;
				z-index: 100;
				padding: 1rem 1rem;
				min-width: 100vw;
				top: 1.4rem;
				color: colors.$color-grey-dark;
				background-color: colors.$color-white;
				flex-direction: column;
				gap: 1.2rem;
				align-items: center;
				justify-content: center;
				border: 1px solid #e6e6e6;
				animation: menuMoveInBottom 0.3s ease-out;
			}

			& > .open {
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
}

// ABOVE 544px (For smaller tablets)
@media only screen and (min-width: 34em) {
}

// ABOVE 704px (For Tablets)
@media only screen and (min-width: 44em) {
}
// ABOVE 944px (For Landscape tablets)
@media only screen and (min-width: 59em) {
}

// ABOVE 1200px (For smaller desktops)
@media only screen and (min-width: 75em) {
	.mainNav {
		&List {
			flex-direction: row;
			font-size: 1.4rem;
			&Item {
				padding: 2rem 0;
				& > .subMenu {
					position: absolute;
					margin: 0;
					min-width: 12rem;
				}
				&:hover span ion-icon {
					transition: all 0.3s ease-in;
					transform: rotate(180deg);
				}
				&:hover span span {
					transition: all 0.3s ease-in;
					transform: rotate(180deg);
				}
			}

			& li ul {
				position: absolute;
				visibility: hidden;
				opacity: 0;
				display: none;
				padding: 1rem;
				color: colors.$color-grey-dark;
			}

			& li:hover > ul,
			& li ul:hover {
				visibility: visible;
				opacity: 1;
				display: flex;
				text-align: center;
				top: 4.8rem;
				background-color: colors.$color-white;
				flex-direction: column;
				gap: 1.2rem;
				align-items: center;
				justify-content: center;
				min-width: 12rem;
				border: 1px solid #e6e6e6;
				animation: menuMoveInBottom 0.3s ease-out;
				& li:hover {
					color: colors.$color-primary;
				}
			}
		}
	}
}

// ABOVE 1344px (For desktops)
@media only screen and (min-width: 84em) {
}
